<template>
  <div class="page">
    <div class="title">企业基础信息</div>
    <el-alert
      title="企业认证后，该用户下所有活动将视为企业行为，由该企业承担相应的权益和责任。"
      type="error"
      style="margin-top: 30px"
    >
    </el-alert>

    <el-divider></el-divider>

    <el-form
      ref="form"
      :rules="rules"
      label-position="top"
      :model="form"
      label-width="80px"
    >
      <el-form-item label="营业执照上传识别" prop="license">
        <el-image
          v-if="form.license"
          :src="host + form.license"
          style="width: 100px; height: 100px"
          fit="cover"
          @click="chooseImage"
          @error="form.license = ''"
        ></el-image>
        <div v-else class="add_photo" @click="chooseImage">
          <i class="el-icon-plus"></i>
        </div>
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          @change="uploadImage"
          @click="
            (event) => {
              event.target.value = null;
            }
          "
          style="width: 0; height: 0; position: absolute"
        />
      </el-form-item>

      <el-row>
        <el-col :span="12">
          <el-form-item label="企业名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="证照编号" prop="license_number">
            <el-input v-model="form.license_number"></el-input>
          </el-form-item>
          <el-form-item label="成立日期" prop="incorporation_date">
            <el-date-picker
              v-model="form.incorporation_date"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="企业法人" prop="legal_person">
            <el-input v-model="form.legal_person"></el-input>
          </el-form-item>
          <el-form-item label="实际控制人" prop="actual_legal_person">
            <el-input v-model="form.actual_legal_person"></el-input>
          </el-form-item>
          <el-form-item label="营业范围" prop="business">
            <el-input v-model="form.business"></el-input>
          </el-form-item>
          <el-form-item label="营业限期" prop="business_term">
            <el-input v-model="form.business_term"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="form_btns">
              <div class="custom_button plain no_select" @click="onSubmit">
                提交认证
              </div>
              <div class="custom_button no_select" @click="onReset">重置</div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="税号" prop="tax_number">
            <el-input v-model="form.tax_number"></el-input>
          </el-form-item>
          <el-form-item label="社会统一信用代码" prop="social_code">
            <el-input v-model="form.social_code"></el-input>
          </el-form-item>
          <el-form-item label="企业类型" prop="company_type">
            <el-input v-model="form.company_type"></el-input>
          </el-form-item>
          <el-form-item label="注册资本" prop="registered_capital">
            <el-input v-model="form.registered_capital"></el-input>
            <span style="margin-left: 14px">万</span>
          </el-form-item>
          <el-form-item label="法人证件号码" prop="certificate">
            <el-select
              v-model="form.certificate_type"
              placeholder="请选择"
              style="width: 160px"
            >
              <el-option
                v-for="item in credentials_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="form.certificate"
              style="width: 230px; margin-left: 10px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="实际控制人身份证"
            prop="actual_legal_person_idcard"
          >
            <el-input v-model="form.actual_legal_person_idcard"></el-input>
          </el-form-item>
          <el-form-item label="企业地址" prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      host: process.env.VUE_APP_BASE_API,
      credentials_options: [
        {
          value: "身份证",
          label: "身份证",
        },
        {
          value: "护照",
          label: "护照",
        },
      ],
      form: {
        name: "",
        license: "",
        tax_number: "",
        license_number: "",
        company_type: "",
        incorporation_date: "",
        registered_capital: "",
        legal_person: "",
        certificate_type: "身份证",
        certificate: "",
        actual_legal_person: "",
        actual_legal_person_idcard: "",
        business_term: "",
        address: "",
        business: "",
        social_code: "",
      },
      rules: {
        social_code: [
          {
            required: true,
            message: "请填写社会统一信用代码",
            trigger: "blur",
          },
          {
            pattern: /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/,
            message: "请填写正确的社会统一信用代码",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请填写企业名称",
            trigger: "blur",
          },
        ],
        license: [
          {
            required: true,
            message: "请上传营业执照",
            trigger: "blur",
          },
        ],
        tax_number: [
          {
            required: true,
            message: "请填写税号",
            trigger: "blur",
          },
          {
            pattern:
              /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/,
            message: "请填写正确的税号",
            trigger: "blur",
          },
        ],
        company_type: [
          {
            required: true,
            message: "请填写企业类型",
            trigger: "blur",
          },
        ],
        incorporation_date: [
          {
            required: true,
            message: "请选择成立日期",
            trigger: "blur",
          },
        ],
        registered_capital: [
          {
            required: true,
            message: "请填写注册资本",
            trigger: "blur",
          },
        ],
        legal_person: [
          {
            required: true,
            message: "请填写企业法人",
            trigger: "blur",
          },
        ],
        certificate: [
          {
            required: true,
            message: "请填写法人证件号码",
            trigger: "blur",
          },
        ],
        business_term: [
          {
            required: true,
            message: "请填写营业限期日期",
            trigger: "blur",
          },
        ],
        address: [{ required: true, message: "请填写住所", trigger: "blur" }],
      },
    };
  },

  created() {
    this.getEnterpriseAuthInfo()
      .then((res) => {
        console.log(res);
        this.form = res.data.data;
      })
      .catch((e) => {
        console.error(e);
        // if (e.code === 404) {
        //   this.$message.error("未认证");
        // }
      });
  },
  methods: {
    ...mapActions(["uploadFile"]),
    ...mapActions("enterprise", [
      "getEnterpriseAuthInfo",
      "postEnterpriseAuth",
      "uploadLicense",
    ]),

    chooseImage() {
      this.$refs.fileInput.click();
    },

    uploadImage(file) {
      console.log(file);
      let loading1 = this.$loading();
      this.uploadLicense(file.target.files[0])
        .then((res) => {
          loading1.close();
          console.log(res);
          res = res.content;
          if (!this.form.name) this.form.name = res.Name;
          if (!this.form.address) this.form.address = res.Address;
          if (!this.form.company_type) this.form.company_type = res.Type;
          if (!this.form.registered_capital)
            this.form.registered_capital = res.Capital;
          if (!this.form.legal_person) this.form.legal_person = res.Person;
          if (!this.form.business_term) this.form.business_term = res.Period;
          if (!this.form.tax_number) this.form.tax_number = res.RegNum;
          if (!this.form.business) this.form.business = res.Business;
          if (!this.form.incorporation_date)
            this.form.incorporation_date = res.SetDate.replace(
              /[年|月|日]/g,
              "-"
            );
        })
        .catch((err) => {
          loading1.close();
          console.error(err);
          this.$message.error(err.msg);
        });
      let loading2 = this.$loading();
      this.uploadFile(file.target.files[0])
        .then((res) => {
          loading2.close();
          console.log(res);
          this.form.license = res.data.data.full_link;
        })
        .catch((err) => {
          loading2.close();
          console.error(err);
          this.$message.error(err.msg);
        });
    },

    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form);
          let loading = this.$loading();
          this.postEnterpriseAuth(this.form)
            .then((res) => {
              console.log(res);
              this.$message.success(res.data.msg);
              this.$nextTick(() => {
                loading.close();
              });
            })
            .catch((e) => {
              console.error(e);
              this.$message.error(e.msg);
              this.$nextTick(() => {
                loading.close();
              });
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },

    onReset() {
      this.$confirm("重置表单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$refs.form.resetFields();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.add_photo {
  width: 100px;
  height: 100px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  color: #dcdfe6;
}

::v-deep .el-form-item__label {
  font-size: 14px;
  color: #222222;
}

.el-input {
  width: 400px;
  height: 48px;
  font-size: 14px;
  color: #222222;
}

.form_btns {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .custom_button {
    width: 142px;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
  }

  > div:not(:first-child) {
    margin-left: 20px;
  }
}
</style>
